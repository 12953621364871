import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const VideoSlide = ({ scale }) => {
    // const [isVisible, setIsVisible] = useState(false);
    // const slideRef = useRef(null);

    // useEffect(() => {
    //     const observerCallback = (entries) => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 setIsVisible(true);
    //             } else {
    //                 setIsVisible(false);
    //             }
    //         });
    //     };

    //     const observerOptions = {
    //         root: null,
    //         rootMargin: '0px',
    //         threshold: 0.7
    //     };

    //     const observer = new IntersectionObserver(observerCallback, observerOptions);
    //     if (slideRef.current) {
    //         observer.observe(slideRef.current);
    //     }

    //     return () => {
    //         if (slideRef.current) {
    //             observer.unobserve(slideRef.current);
    //         }
    //     };
    // }, []);

    return (
        <motion.div
            // ref={slideRef}
            // initial={{ opacity: 0, y: 20 }}
            // animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            // transition={{ duration: 0.5 }}
            className='slide-conteiner'
        >
            <div className="max-w-[1620px] leading-none text-white flex flex-col gap-2 uppercase font-medium italic w-full z-10 text-center" style={{ transform: `scale(${scale})` }}>
                <p className='lg:mb-16' style={{ fontSize: 'clamp(48px, 8vw, 77px)' }}>СМОТРИ ВИДЕО</p>
                <p style={{ fontSize: 'clamp(16px, 4vw, 52px)' }}>Winline преобразил спорт и подарил Косицыну новые впечатления</p>
            </div>
        </motion.div>
    );
};


export default VideoSlide;